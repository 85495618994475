import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../containers/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import BlockContent from '../components/block-content';

const query = graphql`
  query PrivacyPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      _rawPrivacyPolicy
    }
  }
`;

const ContactPage = () => {
  const data = useStaticQuery(query);
  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={'Tietosuojaseloste'} description={site.description} keywords={site.keywords} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        {site._rawPrivacyPolicy && <BlockContent blocks={site._rawPrivacyPolicy || []} />}
      </Container>
    </Layout>
  );
};

export default ContactPage;
